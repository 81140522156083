import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import serverStatusUrl from '../onboarding/images/server-status.svg';
import { RegistrationLayout } from '../../layouts';
import { RegisterForm } from '../forms/register-form';
import { authService } from '../../entities';
import { history } from '../../packages';
import { PathNames } from '../../consts';
import { WelcomeTxt } from '../../pages/welcome/WelcomeTxt';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInfoBox = styled.div`
  flex: 1;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledImageBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    order: -1;
    margin-bottom: 20px;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const SignUp = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    const signupForm = document.getElementById('signupForm');
    if (signupForm) {
      signupForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.register(values));
    },
    [dispatch]
  );

  const onBackHandler = useCallback(() => {
    history.push({ pathname: `${PathNames.welcome}`, state: { modal: true } });
  }, [dispatch]);

  return (
    <RegistrationLayout>
      <StyledWrapper>
        <StyledInfoBox>
          <WelcomeTxt />
          <RegisterForm
            onSubmitHandler={onSubmit}
            onBackHandler={onBackHandler}
            id="signupForm" // Added ID for scrolling
          />
        </StyledInfoBox>
        <StyledImageBox>
          <StyledImage src={serverStatusUrl} alt="Server Status" />
        </StyledImageBox>
      </StyledWrapper>
    </RegistrationLayout>
  );
});