import React, { memo } from 'react';
import styled from 'styled-components';
import branding from '../../branding';

const StyledLink = styled.a`
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const StyledList = styled.ul`
  margin-left: 20px;
  margin-bottom: 10px;
`;

const StyledParagraph = styled.p`
  margin-bottom: 10px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
`;

const StyledHeader = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--text-color);
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding-bottom: 3px;
`;

const StyledListItem = styled.li`
  margin-bottom: 5px;
`;

const StyledWelcomeLabel = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
`;

export const WelcomeTxt = memo(() => {
  return (
    <div
      style={{
        '--primary-color': '#1a237e',
        '--secondary-color': '#00b0ff',
        '--text-color': '#37474f',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px',
        fontFamily: 'Roboto, sans-serif',
        width: '95%',
        maxWidth: '1000px',
        margin: '0 auto',
      }}
    >
      <StyledWelcomeLabel>Welcome to {branding.name}!</StyledWelcomeLabel>
      <StyledHeader>Aribot: Threat Modeling at scale. Secure Everything.</StyledHeader>
      <StyledParagraph>Ready to lead in proactive security?</StyledParagraph>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Stop reacting, start predicting.</strong> Aribot's AI powers
            threat modeling, securing your apps, cloud (AWS, Azure, Google
            Cloud), and pipelines.
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
      <StyledHeader>Aribot: Your Threat Modeling embedded in your design</StyledHeader>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Automated Threat Modeling:</strong> Cut time by 80%, embedding
            security by design across your entire landscape.
            <StyledLink href="https://www.aristiun.com">
              See how – Start free trial!
            </StyledLink>
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Multi-Cloud Security:</strong> Proactively secure AWS, Azure,
            and Google Cloud using the power of threat modeling. Discover
            simplified multi-cloud security.
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Secure Development:</strong> Demonstrate compliance across
            NIST CSF, ISO 27001, SOC 2, OWASP Top Ten using threat modeling as a
            foundation. See trust built into your development.
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
      <StyledHeader>Beyond Aribot: Proactive Threat Insights</StyledHeader>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Threat and vulnerabilities Discovery:</strong> AI-powered
            analysis prioritizes risks
            <StyledLink href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/aristiunbv1648901047675.aribot-automated-threat-modeling?tab=Overview">
              Request a demo.
            </StyledLink>
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Compliance & Risk:</strong> Automate adherence to NIST,
            Fedramp, NIS2, DORA, ISO 27001, SOC 2, GDPR, HIPAA, and 40+ more
            frameworks with AI-powered automation for insights and evidence.
            Ready to simplify?
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Predictive Mitigation:</strong> Prevent threats to
            microservices, containers, and serverless functions.
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
      <StyledHeader>Get Started, Securely.</StyledHeader>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Seamless Integration:</strong> Aribot connects to Azure
            DevOps, GitHub, Jenkins, GitLab CI, and more, making threat
            modeling part of your workflow.
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Rapid Insights:</strong> See immediate security improvements
            in Azure DevOps, GitHub, or Jenkins:
            <StyledLink href="https://marketplace.visualstudio.com/items?itemName=AristiunBV.aribotintegration">
              Aribot - Ayurak AI
            </StyledLink>
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
      <StyledHeader>You'll Immediately:</StyledHeader>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Slash Threat Modeling Time:</strong> for secure code.
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Gain Cloud Control:</strong> across AWS, Azure, Google
            Cloud through your threat insights.
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Demonstrate Compliance:</strong> using frameworks aligned to
            your threat posture.
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>Unlock Proactive Security:</strong> with actionable insights.
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
      <StyledHeader>Need to scale with less resources? Let's Go!</StyledHeader>
      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            <strong>Integrating questions?
              <StyledLink href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/aristiunbv1648901047675.aribot-automated-threat-modeling?tab=Overview">
                Talk to an Expert!
              </StyledLink>
            </strong>
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledParagraph>
            <strong>
              See how easy it is:
              <StyledLink
                href="https://youtube.com/playlist?list=PLnW8hs1GG7Xr9vbgvRch-5h7i-kF2Cvp-"
              >
                {branding.name} - powered by {branding.poweredBy}
              </StyledLink>
            </strong>
          </StyledParagraph>
        </StyledListItem>
      </StyledList>
    </div>
  );
});