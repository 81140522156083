import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import serverStatusUrl from '../onboarding/images/server-status.svg';
import { Button, history } from '../../packages';
import { PathNames } from '../../consts';
import { RegistrationLayout } from '../../layouts';
import { WelcomeTxt } from './WelcomeTxt';
import styled from 'styled-components';
import { RegisterForm } from '../forms/register-form';
import { authService } from '../../entities';

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledInfoBox = styled.div`
  flex: 1;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledImageBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  max-width: 80%;
  max-height: 500px;
  height: auto;
  @media (max-width: 768px) {
    max-width: 100%;
    max-height: none;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledFormContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const Welcome = memo(() => {
  const dispatch = useDispatch();
  const [showSignupForm, setShowSignupForm] = useState(false);
  const firstInputRef = useRef(null);

  const logInWithHandler = useCallback(() => {
    history.push({ pathname: `${PathNames.login}`, state: { modal: true } });
  }, [dispatch]);

  const handleSignUpClick = useCallback(() => {
    setShowSignupForm(true);
  }, []);

  const handleBackToWelcome = useCallback(() => {
    setShowSignupForm(false);
  }, []);

  const handleRegisterSubmit = useCallback(
    (values) => {
      dispatch(authService.register(values));
    },
    [dispatch]
  );

  useEffect(() => {
    if (showSignupForm && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [showSignupForm]);

  return (
    <RegistrationLayout>
      <StyledWrapper>
        <StyledInfoBox>
          <WelcomeTxt />
          {!showSignupForm && (
            <StyledButtonsWrapper>
              <StyledButton
                variant="contained"
                onClick={logInWithHandler}
                sx={{ marginRight: '20px', width: 150, minWidth: 100 }}
              >
                Log In
              </StyledButton>
              <StyledButton
                variant="contained"
                onClick={handleSignUpClick}
                sx={{ width: 150, minWidth: 100 }}
              >
                Sign Up
              </StyledButton>
            </StyledButtonsWrapper>
          )}

          {showSignupForm && (
            <StyledFormContainer>
              <RegisterForm
                onSubmitHandler={handleRegisterSubmit}
                onBackHandler={handleBackToWelcome}
                firstInputRef={firstInputRef}
              />
            </StyledFormContainer>
          )}
        </StyledInfoBox>
        <StyledImageBox>
          <StyledImage src={serverStatusUrl} alt="Server Status" />
        </StyledImageBox>
      </StyledWrapper>
    </RegistrationLayout>
  );
});